import React, { useState } from 'react'
import "./style/menu.scss"
import { Link } from 'react-router-dom';


export default function Menu() {

const[navbar, setNavbar] = useState("navbar navbar-expand-lg navbar-inverse fixed-top exacate-menu")


  function changeNavbar() {
    
    console.log(window.scrollY);
    if(window.scrollY >= 10){
      setNavbar("navbar navbar-expand-lg navbar-inverse fixed-top exacate-menu2");
    }
    else{
      setNavbar("navbar navbar-expand-lg navbar-inverse fixed-top exacate-menu");
    }

  }

  window.addEventListener("scroll", changeNavbar)

  return (
     
    <nav class={navbar}>
    <div class="container-fluid container-sm">
      <a class="navbar-brand" href="#" style={{color:'white!important'}}>EXACATE</a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse " id="navbarText">
        <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <Link class="nav-link " to="/" aria-current="page" >Home</Link>
          </li>
          <li class="nav-item">
          <Link class="nav-link " to="/" aria-current="page" >Seasons</Link>
          </li>
          <li class="nav-item">
          <Link class="nav-link " to="/" aria-current="page" >Researchs</Link>
          </li>
          <li class="nav-item">
          <Link class="nav-link " to="/whoarewe" aria-current="page" >Applications</Link>
          </li>
          <li class="nav-item">
            <Link class="nav-link" to="whoarewe" id="waw">Who Are We?</Link>
          </li>
         
          
          
        </ul>
      
      </div>
    </div>
  </nav>
  )
}
