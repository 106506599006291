import React from 'react'
import Menu from './partials/menu'
import Jumbutron from './partials/jumbotron'
import Princples from './partials/princples'

export default function WhoAreWe() {

 

  return (
    <div>

<Menu></Menu>
<Jumbutron></Jumbutron>
<Princples></Princples>
    </div>
  )
}
