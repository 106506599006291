import React from 'react'

export default function Jumbutron() {
  return (
    <div  style={{paddingTop:"170px", paddingBottom:"80px", backgroundColor: "rgb(13 13 13)", color:"white"}}>
        
        <div className="container">
            <div className='row justify-content-center text-center'>
                <div className="col-sm-6">
              <h1 style={{fontWeight:"300"}}>Who are we?</h1>
              <p style={{fontWeight:"200"}}>At its core, we are a company that is a powerhouse of digital transformation and R&D. As Exacate, we are deeply committed to our standards and principles, but our principles do not hold us captive, on the contrary, we believe that they will liberate us even more.  If the future is digital transformation, our victory is to realise digital transformation in the best way!</p>
              </div>
              <div className="col-sm-6">

                    <img src="logo.png" style={{width:"50%"}}></img>

              </div>
            </div>
        </div>
        
    </div>
  )
}
