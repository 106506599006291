import React from 'react'
import { BrowserRouter, Route, Router, Routes,Switch} from 'react-router-dom'
import Main from './Main'
import WhoAreWe from './WhoAreWe'
import Menu from './partials/menu'
import Banner from './partials/banner'

export default function App() {
  return (
    <BrowserRouter>
    <Routes>
    <Route path="/" element={<Main />} />
    <Route path="/whoarewe" element={<WhoAreWe />} />
  </Routes>
  </BrowserRouter>
  )
}
