import logo from './logo.svg';
import './App.css';


function App() {
  return (
    <div className="App">
      <header className="App-header">
    
        <a id="header">
          EXACATE
        </a>
        <a
          className="App-link"
          id="slogan"

          target="_blank"
          rel="noopener noreferrer"
        >
          Ad Victoriam
        </a>
  
       
        <a
       
       id="new_slogan"
       
     >
       Coming Soon
     </a>
      </header>
     
    </div>
  );
}

export default App;
