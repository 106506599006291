import React, { Component } from 'react'
import Menu from './partials/menu'
import Container from './partials/container'
import Checkout from './partials/chekout'
import Carousel from './partials/carousel'
import Banner from './partials/banner'
import App from './ComingSoon'

export default class Main extends Component {
  render() {
    return (
      <div>
        <App></App>

{/*         <Menu></Menu>
        <Container></Container>
        <Carousel/>
        <Checkout></Checkout>
        <Banner></Banner> */}
      </div>
    )
  }
}
